import { Injectable } from '@angular/core';
import { DataFormModel } from '../models/data-form-model.model';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }

  buildForm(obj: DataFormModel): FormGroup {

    const group: { [key: string]: FormControl } = {};

    Object.keys(obj).forEach(key => {
      group[key] = new FormControl(obj[key].value, obj[key].validators);
    });

    return new FormGroup(group);
    
  }

  generateRandomString(length: number): string { 
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; 
    let result = ''; 
    for (let i = 0; i < length; i++) { 
      const randomIndex = Math.floor(Math.random() * characters.length); 
      result += characters.charAt(randomIndex); 
    } 
    
    return result; 
  }
}
